// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$grid-breakpoints: (
 xs: 0, // Phone (0px - 575px)
 sm: 576px, // Phablet (576px - 767px)
 md: 768px, // Tablet vertical (768px - 991px)
 lg: 992px // Tablet horizontal, Desktop (992px and above)
);



:root {
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56,128,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
  
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12,209,232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;
  
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112,68,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;
  
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16,220,96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;
  
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255,206,0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
  
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245,61,61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;
  
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,34,34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
  
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,244,244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;



    --ion-color-pilot: #459d36;
    --ion-color-pilot-rgb: 244,244,244;
    --ion-color-pilot-contrast: #000000;
    --ion-color-pilot-contrast-rgb: 0,0,0;
    --ion-color-pilot-shade: #3d8a30;
    --ion-color-pilot-tint: ##58a74a;


    --ion-color-company: #bc3c9d;
    --ion-color-company-rgb: 244,244,244;
    --ion-color-company-contrast: #000000;
    --ion-color-company-contrast-rgb: 0,0,0;
    --ion-color-company-shade: #a5358a;
    --ion-color-company-tint: #c350a7;



    // --ion-color-google: #DB4437;
    // --ion-color-google-rgb: 219,68,55;
    // --ion-color-google-contrast: #ffffff;
    // --ion-color-google-contrast-rgb: 255, 255, 255;
    // --ion-color-google-shade: #DB4437;
    // --ion-color-google-tint: rgb(249, 115, 103);

  }
 
  .ion-color-google {
    --ion-color-base: var(--ion-color-google) !important;
    --ion-color-base-rgb: var(--ion-color-google-rgb) !important;
    --ion-color-contrast: var(--ion-color-google-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-google-shade) !important;
    --ion-color-tint: var(--ion-color-google-tint) !important;
}


  .ion-color-pilot {
    --ion-color-base: #459d36;
    --ion-color-base-rgb: 105,187,123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255,255,255;
    --ion-color-shade: #5ca56c;
    --ion-color-tint: #78c288;
  }

  .ion-color-company {
    --ion-color-base: #bc3c9d;
    --ion-color-base-rgb: 105,187,123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255,255,255;
    --ion-color-shade: #a5358a;
    --ion-color-tint: #c350a7;
  }
  
  pre{
    color:maroon;
    white-space: pre-line; 
    display: block;
    font-size:12px;
}


            

.flexbox {
    width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
	}
  .pilot-box,
  .recruiter-box {
    -webkit-flex: 1;
    flex: 1;
    background: none;
    padding:20px;
    color:white;
    h2{
        font-size:20px;
        text-align: center;
    }
  }

.pilot-box{
    background-color: #459d36;
    height:30vh;
}
.recruiter-box{
    background-color: #bc3c9d;
    height:30vh;
}



.pilot{
    color: #459d36;
    font-size:36px;
}
.pilot{
    color: #459d36;
    font-size:36px;
}
.circle-pic, .circle-premium-icon{
   width:100px !important;
   height:100px !important;
   -webkit-border-radius: 50%;
   border-radius: 50%;
   display: block;
    margin-left: auto;
    margin-right: auto;
    border:5px solid white;
}
.circle-premium-icon{
    width:12px !important;
   height:12px !important;
   float:right;
   border:1px solid white;
   background: white;
   margin-left:10px;
   color:gray;
}

.pilot-profile, .user-profile{
    background-color: #459d36;   
    padding-top:20px;
    

    .account-data-holder{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        div, h4{
            color:white;
            display: block;
            
        }
    }
     

    .email, .location{
        font-size:small;
    }
    .location{
        margin-bottom:20px;
    }
    
}

.company-profile, .user-profile{
    background-color: #bc3c9d;   
    padding-top:20px;
    

    .account-data-holder{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        div, h4{
            color:white;
            display: block;
            
        }
    }
     

    .email, .location{
        font-size:small;
    }
    .location{
        margin-bottom:20px;
    }
    
}

.user-profile{
    background-color: #f8f8f8;
    margin: -20px -20px 20px -20px;
    border-bottom: 1px solid #ccc;
    div, h4{
        color:black !important;
        
    }
   
}
.pull-right{
    float:right;
    
}
.edit-lnk{
    margin-top:-20px;
    color:white;
    font-size:1rem;
    
}
.cv-text{
    font-size:small;
    color:gray;
    margin-left:5px;
    h2{
        small{
            font-size:small;
            color:gray;
        }
    }
    a:link, a:visited{
        text-decoration: underline;
    }
}
.expired-item{
    color:#ccc;
}

.logo-login, .logo-main{
    width:80%;
  
    max-width: 400px;
    display: block;
    margin:50px auto 30px auto;
}


.photo-main, .photo-news{
    max-height: 50vh;
    margin:auto;
    display: block;
}

.home-links a{
    font-size:small;
    color:black;
    text-decoration: none;
}

.bg-login-screen{
    height: 100%;
    display: block;
    vertical-align: middle;
    border:1px solid red;
}

.vertical-align-content > div
{
     display: flex!important;
     align-content: center!important;
     align-items: center!important;
}


.bar-holder{
    height: 30px;
    max-width: 100%;
    margin: 0 auto 10px auto;
    line-height: 30px;
    font-size: 16px;
    color: white;
    padding: 0;
    border:0px solid red;
    background-color: #eeeeee;
    
    .bar{
        background-color: #459d36;
        height: 100%;
        padding-left:10px;
        font-size:small;


    }
  }
  
.largeIcon{
    font-size:64px;

}

.divider-block{
  
    background-color: var(--ion-color-light);

}

.searchTools{
    background-color: var(--ion-color-light);
    width:100%; 
    display:block;
    margin-bottom:2px;
    z-index:1000; 
    position: sticky;
    top: 0;
}

.filterBtn{
    margin-top:5px;
}


@media (max-width: map-get($grid-breakpoints, sm)) {
    .photo-main{
        display:none;
    }
    .photo-main-mobile{
        display:block;
    }
}
@media (min-width: map-get($grid-breakpoints, md)) {
    .photo-main{
        display:block;
    }
    .photo-main-mobile{
        display:none;
    }
}

.text-underline{
    text-decoration: underline;
}